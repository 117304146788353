<template>
    <div class="form-text-check">
        <input :id="name" v-model="checked" :name="name" type="checkbox">
        <div class="form-control">
            <label :for="name">{{ checkedTextData }}</label>
            <label :for="name">{{ uncheckedTextData }}</label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'text-check-input',
    props: {
        name: {
            String,
            required: true
        },
        checked: {
            Boolean
        },
        uncheckedtext: {
            String,
            required: true
        },
        checkedtext: {
            String,
            required: true
        }
    },
    data() {
        return {
            uncheckedTextData: this.uncheckedtext,
            checkedTextData: this.checkedtext
        }
    }
}
</script>
<style lang="scss">
.form-text-check input[type=checkbox] {
    display: none;

    //Inaktiv
    & + .form-control {
        background-color: var(--bs-light);

        & > label {
            text-align: center;
            margin: -.375rem -.75rem;
            padding: .375rem .75rem;

            //Aktiv Label
            &:first-child {
                display: none;
                color: var(--bs-light);
            }

            //Inaktiv Label
            &:nth-child(2) {
                display: block;
            }
        }
    }

    //Aktiv
    &:checked {

        & + .form-control {
            background-color: var(--bs-primary);

            & > label {
                &:first-child {
                    display: block;
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}
</style>
