document.addEventListener('DOMContentLoaded', () => {

    if (LOCK_URL) {

        const config = {
            method: 'GET',
            headers: {'X_CSRF_TOKEN': CSRF}
        }

        setInterval(() => {
            fetch(LOCK_URL, config)
        }, 60 * 1000)
    }
})

