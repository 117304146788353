import {createApp} from 'vue'
import Dropdown from "./components/Dropdown";
import AttendanceHistory from "./components/AttendanceHistory";
import TextCheckInput from "./components/TextCheckInput";

const app = createApp({});

app.component('dropdown', Dropdown)
    .component('attendanceHistory', AttendanceHistory)
    .component('textCheckInput', TextCheckInput)
    .mount('#app');

window.vue = app;
