<template>
    <div class="form-control form-control-scrollable">
        <div v-for="entry of history" class="row mt-2">
            <div class="col-3">{{ formatDateTime(entry.date) }}</div>
            <div class="col">{{ formatEntry(entry) }}</div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: 'attendanceHistory',
    props: {
        url: {
            String,
            required: true
        }
    },
    data() {
        return {
            history: null
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData: function () {
            axios
                .get(this.$props.url)
                .then(response => (this.history = response.data))
        },

        formatEntry: function (entry) {
            const userName = entry.user ?? 'Unbekannt';

            if (entry.status === 0) {
                return userName + ' hat ' + entry.name + ' "' + entry.value + '" erstellt.';
            } else if (entry.status === 2) {
                return userName + ' hat ' + entry.name + ' gelöscht.';
            } else {
                return userName + ' hat ' + entry.name + ' auf "' + entry.value + '" geändert.';
            }
        },

        formatDateTime: function (date) {
            return date.slice(0, 19).replace("T", " ");
        }
    }
}
</script>
