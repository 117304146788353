document.addEventListener('DOMContentLoaded', () => {

    //Reset note after successful submit
    const notesInput = document.getElementById('note');
    const noteForm = document.getElementById('noteForm');

    if (notesInput !== null && noteForm !== null) {

        noteForm.addEventListener('submitted', () => {
            notesInput.value = '';
        })
    }
})
