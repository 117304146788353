<template>
    <div class="dropdown">
        <a id="dropdownMenuLink"
           :data-type-button="type"
           class="btn btn-secondary btn-block dropdown-toggle"
           data-bs-toggle="dropdown">
            {{ displayText }}
        </a>

        <input :name="name" :value="value" hidden/>
        <ul class="dropdown-menu">
            <template v-for="item of items">
                <dropdown-divider v-if="item.item === 'divider'"></dropdown-divider>
                <dropdown-item v-if="item.item === 'item'"
                               :initial="item.initial"
                               :text="item.text"
                               :type="item.type"
                               :value="item.value"
                               v-on:value="setValue"></dropdown-item>
            </template>
        </ul>
    </div>
</template>
<script>
import DropdownItem from "./DropdownItem";
import DropdownDivider from "./DropdownDivider";

export default {
    name: 'dropdown',
    props: {
        text: {
            String,
            required: true
        },
        name: {
            String,
            required: true
        },
        items: {
            Array,
            default: []
        }
    },
    components: {
        DropdownItem,
        DropdownDivider
    },
    data() {
        return {
            value: {},
            type: {},
            displayText: this.text
        }
    },
    methods: {
        setValue: function (value, text, type) {
            this.value = value;
            this.displayText = text;
            this.type = type;
            document.documentElement.dispatchEvent(new CustomEvent(this.$props.name, {detail: value}));
        }
    }
}
</script>
