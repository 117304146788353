//Open Zoom-Window onclick
document.addEventListener('click', ev => {

    let image = ev.target.closest('.receipt-zoom');

    if (image == null)
        return;

    if (image.src === null)
        image = image.querySelector('img');

    if (image == null)
        return;

    window.open(image.dataset.receiptZoom, "Bon-Zoom", "height=1000,width=500,left=300,top=200");
})

//Init Zoom-Box
document.addEventListener('DOMContentLoaded', () => {
    const zoomBox = document.getElementById('receipt-zoom');

    if (zoomBox != null)
        new ZoomBox(zoomBox);
})

class ZoomBox {

    static scaleStepping = .2;
    static rotateStepping = 30;

    static  IN = 1 + this.scaleStepping;
    static  OUT = 1 - this.scaleStepping;
    static  LEFT = -this.rotateStepping;
    static  RIGHT = this.rotateStepping;

    style = null;

    currentRotation = 0;
    currentScale = 1;
    posX = 0;
    posY = 0;

    //Drag
    initialX = 0;
    initialY = 0;
    xOffset = 0;
    yOffset = 0;
    active = false;

    constructor(zoomBox) {

        this.style = zoomBox.style;

        //Buttons
        document.addEventListener('click', ev => {
            const btn = ev.target.closest('.btn');

            if (btn == null)
                return;

            const id = btn.id;

            switch (id) {
                case 'zoom-in':
                    this.scale(ZoomBox.IN);
                    break;
                case 'zoom-out':
                    this.scale(ZoomBox.OUT);
                    break;
                case 'rotate-left':
                    this.rotate(ZoomBox.LEFT);
                    break;
                case 'rotate-right':
                    this.rotate(ZoomBox.RIGHT);
                    break;
                case 'upside-down':
                    this.rotate(180);
                    break;
                case 'reset':
                    this.currentScale = 1;
                    this.scale(1);

                    this.currentRotation = 0;
                    this.rotate(0);

                    this.posX = 0;
                    this.posY = 0;
                    this.translate(this.posX, this.posY);

                    break;
            }
        })

        //Mouse-Wheel
        // document.addEventListener('wheel', () => {
        //     if (event.deltaY > 1)
        //         this.scale(ZoomBox.IN);
        //     else if (event.deltaY < 1)
        //         this.scale(ZoomBox.OUT);
        // })

        //Drag
        document.addEventListener("touchstart", ev => this.dragStart(ev));
        document.addEventListener("touchend", ev => this.dragEnd(ev));
        document.addEventListener("touchmove", ev => this.drag(ev));

        document.addEventListener("mousedown", ev => this.dragStart(ev));
        document.addEventListener("mouseup", ev => this.dragEnd(ev));
        document.addEventListener("mousemove", ev => this.drag(ev));
    }

    scale(value) {
        this.currentScale *= value;
        this.style.setProperty("--scale", this.currentScale.toString());
    }

    rotate(value) {
        this.currentRotation += value;
        this.style.setProperty("--rotate", this.currentRotation + "deg");
    }

    translate(x, y) {
        this.style.setProperty("--posX", x + "px");
        this.style.setProperty("--posY", y + "px");
    }

    // region drag event
    dragStart(e) {
        if (e.type === "touchstart") {
            this.initialX = e.touches[0].clientX - this.xOffset;
            this.initialY = e.touches[0].clientY - this.yOffset;
        } else {
            this.initialX = e.clientX - this.xOffset;
            this.initialY = e.clientY - this.yOffset;
        }

        this.active = true;
    }

    drag(e) {
        if (this.active) {
            e.preventDefault();

            if (e.type === "touchmove") {
                this.posX = e.touches[0].clientX - this.initialX;
                this.posY = e.touches[0].clientY - this.initialY;
            } else {
                this.posX = e.clientX - this.initialX;
                this.posY = e.clientY - this.initialY;
            }

            this.xOffset = this.posX;
            this.yOffset = this.posY;

            this.translate(this.posX, this.posY);
        }
    }

    dragEnd() {
        this.initialX = this.posX;
        this.initialY = this.posY;

        this.active = false;
    }

    //endregion
}
