require('bootstrap');
require('startbootstrap-sb-admin/dist/js/scripts.js');
require('./admin/axios-form');
require('./admin/scripts');
require('./admin/archive-confirmation');
require('./admin/destroy-confirmation');
require('./admin/vue');
require('./admin/receipt-zoom');
require('livewire-sortable');
require('alpinejs')
require('./admin/attendance-lock');
require('./admin/clearing-note-form');
require('./admin/status-dropdown-text');
