import {Modal} from 'bootstrap'

document.addEventListener('click', ev => {

    if (ev.target.closest('.confirm-archive') != null) {
        ev.preventDefault();

        const link = ev.target.href;
        const modal = document.getElementById('archive-confirmation-modal');
        modal.getElementsByClassName('confirm-archive-route').item(0).href = link;

        const confirmationModal = new Modal(modal);
        confirmationModal.show();
    }
})
