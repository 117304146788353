document.addEventListener('DOMContentLoaded', () => {

    //Display textbox for other reason
    const mailGroup = document.getElementById('mail-text-group');
    const mailInput = document.getElementById('mail-text');

    if (mailGroup !== null && mailInput !== null) {

        document.documentElement.addEventListener('status', ev => {
            if (ev.detail !== 9) {
                mailGroup.hidden = true;
                mailInput.disabled = true;
            } else {
                mailGroup.hidden = false;
                mailInput.disabled = false;
            }
        });
    }
})
