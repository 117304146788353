<template>
    <li :data-type="type"
        class="dropdown-item"
        @click="onClick">{{ text }}
    </li>
</template>
<script>
export default {
    name: 'dropdown-item',
    props: {
        text: {
            String,
            required: true
        },
        value: {},
        type: {},
        initial: {
            Boolean
        }
    },
    emits: ['value'],
    methods: {
        onClick: function () {
            this.$emit('value', this.value, this.text, this.type);
        }
    },
    mounted() {
        this.$nextTick(function () {
            if (this.initial)
                this.onClick();
        })
    }
}
</script>
