document.addEventListener('submit', ev => {
    const form = ev.target;
    const submitter = ev.submitter;

    if (form.classList.contains('axios-form')) {

        ev.preventDefault();

        const submitButton = form.querySelector('button[type=submit]');
        const oldSubmitMessage = submitButton.innerHTML;

        submitButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lädt...';
        submitButton.disabled = true;

        for (const formControl of form.getElementsByClassName('is-invalid'))
            formControl.classList.remove('is-invalid');

        const action = form.action;
        const formData = new FormData(form);
        const method = form.method ?? 'post';
        const csrf = document.querySelector('meta[name="csrf-token"]').content;

        if (submitter)
            formData.append(submitter.name, submitter.value);

        const axios = require('axios').default;

        axios({
            method: method,
            url: action,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': csrf
            }
        })
            .then(response => {
                form.dispatchEvent(new Event('submitted'));

                if (action !== response.request.responseURL) {
                    window.history.pushState(null, null, response.request.responseURL);

                    document.open();
                    document.write(response.data);
                    document.close();
                }
            })
            .catch(error => {
                if (error?.response?.status === 422) {

                    form.querySelectorAll('.is-invalid').forEach(element => element.classList.remove('is-invalid'));

                    const errors = error.response.data.errors;

                    for (const field in errors) {

                        if (!errors.hasOwnProperty(field))
                            continue;

                        const inputField = form.querySelector('#' + field);
                        const message = errors[field][0];

                        inputField.closest('.form-group').querySelector('.form-control').classList.add('is-invalid');
                        inputField.closest('.form-group').querySelector('.invalid-feedback').innerHTML = message;
                    }
                }
            })
            .finally(function () {
                submitButton.innerHTML = oldSubmitMessage;
                submitButton.disabled = false;
            })
    }
});
